<script setup>
const props = defineProps({
  small: Boolean,
  tiny: Boolean
})

const slots = useSlots();

</script>

<template>
<div :class="['max-w-min whitespace-nowrap flex relative justify-center items-center gap-1 font-light text-azure cursor-pointer select-none',
             {'font-extralight leading-6':small || tiny},
             {'text-sm' : small},
             {'text-xs' : tiny}
             ]"
>
  <div v-if="slots.leftSlot">
    <slot name="leftSlot" />
  </div>
  <div><slot/></div>
  <div v-if="slots.rightSlot">
    <slot name="rightSlot" />
  </div>
</div>
</template>
